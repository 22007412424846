// import React, { useState } from "react";
// import "../components/AdminLogin.css";
// import "../App.css";
// import $ from "jquery";
// import axios from "axios";
// import { useHistory } from "react-router-dom";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { Typography, Button } from '@mui/material';

// export function NewForgotPassword(props) {

//   const [emailId, updateemailId] = useState("");
//   const [errorCode, updateErrorCode] = useState("");
//   const [errorMessage, updateErrorMessage] = useState("");

//   const history = useHistory();

//   return (
//     <div>
//       <section className="unicircle_login">
//         <div className="">
//           <div
//             className="user signinBx"
//             style={{ padding: "0" }}
//             id="accountLogin"
//           >
//             <div className="d-flex" style={{ width: "100%" }}>
//               <div className="login_empty_div">
//                 <div
//                   className="vertical-line"
//                   style={{
//                     boxShadow:
//                       "rgba(0, 0, 0, 0.125) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 1px 0px 0px 0px",
//                   }}

//                 ></div>
//               </div>
//               <div className="login_img_div">
//                 <img
//                   src="dist/img/Group_login_img.png"
//                   style={{ width: "100%", height: "100%" }}
//                 />
//               </div>

//               <div
//                 className="border_class2 login_main_div"
//                 style={{
//                   boxShadow:
//                     "0px 0px 0px 0px rgba(0, 0, 0, .125), 2px 3px 1px 1px rgba(0, 0, 0, .2)",
//                 }}
//               >
//                 <div style={{ padding: "20px" }}>
//                   <div>
//                     <img
//                       src="dist/img/uniLogo.png"
//                       style={{ width: "130px", height: "25px" }}
//                     />
//                   </div>

//                   <div style={{ marginTop: "30px" }}>
//                     <div style={{ display: 'flex', alignItems: 'center' }}>
//                       <ArrowBackIcon style={{ marginRight: '6px' }} />
//                       <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
//                         Back
//                       </Typography>
//                     </div>
//                     <div>
//                       <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "20px" }}>
//                         Forgot Password
//                       </Typography>
//                     </div>

//                   </div>

//                   <div style={{ marginTop: "20px" }}>
//                     <div
//                       className="d-flex all_inputs"
//                       style={{
//                         alignItems: "center",
//                         border: "1px solid #4779F0",
//                       }}
//                     >
//                       <input
//                         type="email"
//                         className=""
//                         id="emailId"
//                         value={emailId}
//                         onChange={(e) => updateemailId(e.target.value)}
//                         placeholder="Super Admin email"
//                         autoComplete="off"
//                         style={{
//                           border: "none",
//                           width: "100%",
//                           marginLeft: "5px",
//                         }}
//                       />
//                     </div>

//                     <div style={{ marginTop: "20px" }}>
//                       <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
//                         We'll send a verification code to this email or phone number if it matches an existing Unicircle account.
//                       </Typography>
//                     </div>

//                     <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: "40px" }}>
//                       <Button
//                         variant="contained"
//                         style={{
//                           background: "#1F3977",
//                           fontWeight: "500",
//                           color: "white",
//                           width: "110px",
//                           height: "30px",
//                           fontSize: "12px",
//                           boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.15)",
//                           marginRight: '20px',

//                         }}
//                         id="loginButton"
//                       >
//                         Send
//                       </Button>
//                       <Button
//                         variant="outlined"
//                         style={{
//                           color: "#1F3977",
//                           borderColor: "#1F3977",
//                           width: "110px",
//                           height: "30px",
//                           fontSize: "12px",
//                           fontWeight: "500",
//                         }}
//                         onClick={() => history.push("/")}
//                       >
//                         Cancel
//                       </Button>
//                     </div>

//                     <div
//                       style={{
//                         fontWeight: "500",
//                         fontFamily: "Poppins",
//                         fontSize: "11px",
//                         marginTop: "10px",
//                       }}
//                     >
//                       {errorCode === 200 ? (
//                         <div className="d-flex">
//                           <img
//                             src={require("../Images/correct.png")}
//                             style={{ width: "18px" }}
//                           />
//                           <span style={{ color: "green" }}>{errorMessage}</span>
//                         </div>
//                       ) : errorCode === 404 ? (
//                         <div className="d-flex">
//                           <img
//                             src={require("../Images/wrong.jpg")}
//                             style={{ width: "18px" }}
//                           />
//                           <span style={{ color: "red" }}>{errorMessage}</span>
//                         </div>
//                       ) : errorCode === 406 ? (
//                         <div className="d-flex">
//                           <img
//                             src={require("../Images/missing.png")}
//                             style={{ width: "15px" }}
//                           />
//                           <span style={{ color: "red", marginLeft: "5px" }}>
//                             Please! Enter Email address and password
//                           </span>
//                         </div>
//                       ) : (
//                         ""
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

import React, { useState } from "react";
import "../components/AdminLogin.css"; // Adjust styles if they affect other pages
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Button } from "@mui/material";
import axios from "axios";

export function NewForgotPassword() {

  const toggleForm = () => {
    const container = document.querySelector(".container");
    container.classList.toggle("active");
  };

  const history = useHistory();
  const [emailId, setEmailId] = useState("");
  // const [errorCode, setErrorCode] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, updateUserEmail] = useState("");
  const [message, setmessage] = useState(false);
  const [errorCode, updateErrorCode] = useState("")
  const [errorMessage, updateErrorMessage] = useState("");
  const [state, setState] = useState(true);


  // const handleEmailValidation = (email) => {
  //   const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   const mobilePattern = /^[0-9]+$/;

  //   if (email.match(mailFormat)) return 0; // Email
  //   if (email.match(mobilePattern)) return 1; // Mobile
  //   return null; // Invalid format
  // };

  async function SearchEmail() {
    if (!userEmail.trim()) {
      updateErrorCode(406);
      updateErrorMessage("Please enter your email or mobile number.");
      return;
    }

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mobilePattern = /^[0-9]+$/;

    let counter;
    if (userEmail.match(mailformat)) {
      counter = 0;
    } else if (userEmail.match(mobilePattern)) {
      counter = 1;
    } else {
      updateErrorCode(406);
      updateErrorMessage("Invalid email or mobile number format.");
      return;
    }

    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("flag", counter);

      const response = await axios.post(
        process.env.REACT_APP_API_KEY + "admin_forgot_password",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);

      if (response.data && response.data.error_code === 200) {
        updateErrorCode(200);
        updateErrorMessage(response.data.message);
        setTimeout(() => {
          history.push("/resetYourPassword", { userEmail });
        }, 3000);
      } else {
        updateErrorCode((response.data && response.data.error_code) || 500);
        updateErrorMessage((response.data && response.data.message) || "An error occurred.");
      }
    } catch (err) {
      setIsLoading(false);
      updateErrorCode(500);
      updateErrorMessage("An error occurred. Please try again later.");
      console.error("Error in SearchEmail:", err);
    }
  }

  return (
    <div>
      <section className="unicircle_login" style={{ position: "relative", zIndex: 1 }}>
        <div className="d-flex justify-center">
          <div className="user signinBx" style={{ padding: "0" }} id="accountLogin">
            <div className="d-flex" style={{ width: "100%" }}>
              {/* Left Vertical Line */}
              <div className="login_empty_div">
                {/* <div
                  className="vertical-line"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 1px 0px 0px",
                  }}
                /> */}
              </div>

              {/* Image Section */}
              <div className="login_img_div">
                <img
                  src="dist/img/admin_login_img.png"
                  alt="Login"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              {/* Form Section */}
              <div
                className="border_class2 login_main_div"
                style={{
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08)", // Adds a soft shadow on all sides
                }}
              >
                <div style={{ padding: "20px" }}>
                  {/* Logo */}
                  <img
                    src="dist/img/uniLogo.png"
                    alt="Unicircle Logo"
                    style={{ width: "130px", height: "25px" }}
                  />

                  {/* Header */}
                  <div style={{ marginTop: "30px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ArrowBackIcon style={{ marginRight: '6px' }} />
                      <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                        Back
                      </Typography>
                    </div>
                    <Typography style={{ fontSize: "16px", fontWeight: "600", marginTop: "20px" }}>
                      Forgot Password
                    </Typography>
                  </div>

                  {/* Email Input */}
                  <div style={{ marginTop: "20px" }}>
                    <div className="d-flex all_inputs" style={{ border: "1px solid #4779F0" }}>
                      <input
                        // type="email"
                        // value={emailId}
                        // onChange={(e) => setEmailId(e.target.value)}
                        // placeholder="Super Admin email"
                        // autoComplete="off"
                        type="text"
                        className="login_plceholder"
                        name="text1"
                        value={userEmail}
                        onChange={(e) => updateUserEmail(e.target.value)}
                        placeholder="Enter Registered Email"
                        autoComplete="off"
                        id="emailValidation"
                        style={{ border: "none", width: "100%", marginLeft: "5px" }}
                      />
                    </div>
                    <Typography style={{ fontSize: "12px", fontWeight: "500", marginTop: "20px" }}>
                      We'll send a verification code if it matches an existing Unicircle account.
                    </Typography>
                  </div>

                  {/* Buttons */}
                  <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: "40px" }}>
                    <Button
                      variant="contained"
                      style={{
                        background: "#1F3977",
                        fontWeight: "500",
                        color: "white",
                        width: "110px",
                        height: "30px",
                        fontSize: "12px",
                        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.15)",
                        marginRight: '20px',
                      }}
                      //   onClick={handleForgotPassword}
                      //   disabled={isLoading}
                      // >
                      value="Search"
                      // onClick={() => SearchEmail(document.form1.text1)}
                      onClick={SearchEmail}

                    >
                      {isLoading ? "Sending..." : "Send"}
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        color: "#1F3977",
                        borderColor: "#1F3977",
                        width: "110px",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      onClick={() => history.push("/")}
                    >
                      Cancel
                    </Button>
                  </div>

                  {/* Error Message */}
                  <div style={{ fontWeight: "500", fontSize: "11px", marginTop: "10px" }}>
                    {errorCode === 200 ? (
                      <span style={{ color: "green" }}>{errorMessage}</span>
                    ) : (
                      <span style={{ color: "red" }}>{errorMessage}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}
