import React, { useState, useEffect } from "react";
import { Menu } from "../Menu";
import { Header } from "../Header";
import axios from "axios";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";


export const NewPollsPreview = () => {

  const [pollsCategory, updatePollsCategory] = useState([]);

  async function fetchPollsCategoryList() {
    const token = localStorage.getItem("Token");

    try {
      const fetchPollsListResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "get_poll_categories",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const PollsCategoryErrorCode = fetchPollsListResponse.data.error_code;
      const PollsCategoryErrorMsg = fetchPollsListResponse.data.message;
      if (PollsCategoryErrorCode == 200) {
        const PollsCategoryListArray = fetchPollsListResponse.data.data;
        updatePollsCategory(PollsCategoryListArray);
      } else {
        updatePollsCategory([]);
        $(".alert-danger").show();
        setTimeout(function () {
          $(".alert-danger").hide();
        }, 3000);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }
  // ******************************Category id************************
  const token = localStorage.getItem("Token");
  const [data, setData] = useState([]);

  const [category, updateCategory] = useState("");
  const [categoryId, updateCategoryId] = useState("");
  const [question, updateQuestion] = useState("");
  const [questionType, updateQuestionType] = useState("");
  const [answers, setAnswers] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: ""
  });
  const [deliveryType, updateDeliveryType] = useState("");
  const [publishDate, updatePublishDate] = useState("");
  const [expireDate, updateExpireDate] = useState("");
  const [pollStatus, updateStatus] = useState("");
  const [pollSendTo, updatePollSendTo] = useState("");
  const [editSendToStudent, updateEditSendToStudent] = useState([]);
  const [childPollData, setChildPollData] = useState([]);
  const [studentId, updateStudentId] = useState([]);

  useEffect(() => {
    const fetchPollData = async () => {
      try {
        const formData = new FormData();
        formData.append("poll_id", /* your_poll_id */);

        const fetchPollResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "admin_get_single_poll",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );

        console.log("fetchPollResponse>>>", fetchPollResponse)

        if (fetchPollResponse.data.error_code === 200) {
          const item = fetchPollResponse.data.data[0];

          updateCategoryId(item.category_id);
          updateCategory(item.category);
          updateQuestion(item.questions);
          updateQuestionType(item.polls_que_type);

          if (item.polls_que_type === 2) {
            setAnswers({
              answer1: item.option_1,
              answer2: item.option_2,
              answer3: item.option_3,
              answer4: item.option_4,
              answer5: item.option_5
            });
          }

          updateDeliveryType(item.delivery_type);
          updatePublishDate(item.publish_date);
          updateExpireDate(item.expire_date);
          updatePollSendTo(item.send_to);

          if (item.send_to === 2) {
            const names = item.sent_to_student.map((s) => s.student_name);
            const studentIds = item.sent_to_student.map((s) => s.student_id);

            setChildPollData(names);
            updateStudentId(studentIds);
          }
        }
      } catch (e) {
        console.error("ERROR OCCURRED", e);
      }
    };

    fetchPollData();
  }, [token]);

  // *****************************************************************

  // **************************Pole result *********************************
  const [pollsCount, updatePollsCount] = useState([]);
  const [pollId, updatePollId] = useState("");

  const [results, setResults] = useState({
    always: 0,
    usually: 0,
    sometime: 0,
    rarely: 0,
    never: 0,
  });
  const [viewQuestion, updateViewQuestion] = useState("");

  async function viewGraph(polls_id, questions) {
    updatePollId(polls_id);
    $(".edit_campus_modal").hide();
    updateViewQuestion(questions);
    $(".view_container").show();
    try {
      const formData = new FormData();
      formData.append("poll_id", polls_id);

      const fetchPollResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "admin_poll_analysis",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      console.log("fetchPollResponse------------------", fetchPollResponse);

      updatePollsCount(fetchPollResponse.data.error_code);

      if (fetchPollResponse.data.error_code === 404) {
        setResults({
          always: 0,
          usually: 0,
          sometime: 0,
          rarely: 0,
          never: 0,
        });
      } else {
        let total = 0;
        fetchPollResponse.data.data.forEach((pollsItem) => {
          total += parseInt(pollsItem.always);
          total += parseInt(pollsItem.usually);
          total += parseInt(pollsItem.sometime);
          total += parseInt(pollsItem.rarely);
          total += parseInt(pollsItem.never);
        });

        fetchPollResponse.data.data.forEach((pollsItem) => {
          const alwaysPercentage = ((parseInt(pollsItem.always) / total) * 100).toFixed(2);
          const usuallyPercentage = ((parseInt(pollsItem.usually) / total) * 100).toFixed(2);
          const sometimePercentage = ((parseInt(pollsItem.sometime) / total) * 100).toFixed(2);
          const rarelyPercentage = ((parseInt(pollsItem.rarely) / total) * 100).toFixed(2);
          const neverPercentage = ((parseInt(pollsItem.never) / total) * 100).toFixed(2);

          setResults({
            always: alwaysPercentage + "%",
            usually: usuallyPercentage + "%",
            sometime: sometimePercentage + "%",
            rarely: rarelyPercentage + "%",
            never: neverPercentage + "%",
          });
        });
      }
    } catch (e) {
      console.log("ERROR OCCURED", e);
    }
  }
  // ***********************************************************************



  useEffect(() => {
    fetchPollsCategoryList();
    // tableCss();
  }, []);


  return (
    <>
      <div>
        <Header />

        <div className="d-flex">
          <Menu />

          <div className="content-wrapper">
            <div className="border_class2 box_padding" style={{ width: "75%" }}>
              <div style={{ display: "flex", justifyContent: "space-between", }}>

                <div className="main_heading_h1" style={{ padding: "2px 30px" }}>
                  <span>Category :</span>
                  <span className="all_labels" style={{ marginLeft: "5px", fontSize: "10px", }}> {category}</span>
                </div>


                <div className="main_heading_h1" style={{ padding: "2px 30px" }}>
                  <span>Publish Date :</span>
                  <span className="all_labels" style={{ marginLeft: "5px", fontSize: "10px", fontWeight: "bold" }}>{publishDate}</span>
                </div>
              </div>
              <hr style={{ width: "95%", marginTop: "10px", marginBottom: "2%", marginLeft: "3%" }} />
              <div>

                <div style={{ padding: "2px 30px" }}>
                  <span className="all_labels" style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "bold" }}>
                    Poll Questions :</span>
                </div>
                <div style={{ padding: "2px 30px" }}>
                  <span style={{ marginLeft: "5px", fontWeight: "600", fontSize: "10px", }}> {question} </span>
                </div>

              </div>

              <div style={{ marginTop: "1%", }} >
                <div style={{ padding: "2px 30px" }}>
                  <span className="all_labels" style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "bold" }}>
                    Poll Results :</span>
                </div>

                <div style={{ padding: "2px 30px", marginTop: "1%", }}>
                  <ul style={{ fontSize: "10px", fontWeight: "600", padding: "0", listStyleType: "none", margin: "0", width: "40%" }}>
                    <li style={{ display: "flex", justifyContent: "space-between", padding: "10px 15px", border: "1px solid #ddd", borderRadius: "5px", marginBottom: "5px" }}>
                      <span>Always</span>
                      <span style={{ color: "#000" }}>{results.always}%</span>
                    </li>
                    <li style={{ display: "flex", justifyContent: "space-between", padding: "10px 15px", border: "1px solid #ddd", borderRadius: "5px", marginBottom: "5px" }}>
                      <span>Usually</span>
                      <span style={{ color: "#000" }}>{results.usually}%</span>
                    </li>
                    <li style={{ display: "flex", justifyContent: "space-between", padding: "10px 15px", border: "1px solid #ddd", borderRadius: "5px", marginBottom: "5px" }}>
                      <span>Sometime</span>
                      <span style={{ color: "#000" }}>{results.sometime}%</span>
                    </li>
                    <li style={{ display: "flex", justifyContent: "space-between", padding: "10px 15px", border: "1px solid #ddd", borderRadius: "5px", marginBottom: "5px" }}>
                      <span>Rarely</span>
                      <span style={{ color: "#000" }}>{results.rarely}%</span>
                    </li>
                    <li style={{ display: "flex", justifyContent: "space-between", padding: "10px 15px", border: "1px solid #ddd", borderRadius: "5px" }}>
                      <span>Never</span>
                      <span style={{ color: "#000" }}>{results.never}%</span>
                    </li>
                  </ul>
                </div>




              </div>


              <div>

                <div style={{ padding: "2px 30px" }}>
                  <span className="all_labels" style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "bold", color: "4779F0" }}>
                    Receiptance :</span>
                </div>
                <div style={{ padding: "2px 30px" }}>

                </div>

              </div>






              <hr style={{ width: "95%", marginBottom: "10px", marginLeft: "3%" }} />

              <div className="row">
                <div className="p-0">
                  <p className="ten_font_class"
                    style={{ color: "#4779F0", padding: "2px 30px" }}
                  >
                    Engagement Analytics
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 pl-0">
                  <section style={{ margin: "15px 0px", padding: "2px 30px" }}>
                    <div className=" table-cards">
                      <div className="table-cards">
                        <div className="row">
                          <div
                            className="col-md-12 p-0"
                            style={{ height: "100%" }}
                          >
                            <div
                              className="small-box"
                              style={{
                                height: "70px",
                                padding: "5px",
                                borderRadius: "2.5PX",
                                display: "flex",
                              }}
                            >
                              <div
                                className="inner"
                                // onClick={UniDetails}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",

                                }}
                              >
                                <div>
                                  <div>
                                    <h5 className="eleven_font_class"
                                      style={{
                                        color: "#1F3977"
                                      }}
                                    >
                                      Views
                                    </h5>
                                  </div>

                                  <div
                                    className="d-flex twenty_font_class"
                                    style={{
                                      flexWrap: "wrap",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {" "}

                                    {/* <div>{n_data.view_count}</div> */}
                                  </div>
                                </div>

                                <div className="all_icon_imgs_div"
                                  style={{
                                    background: "#FBE1FF",

                                  }}
                                >
                                  <img className="all_icon_imgs"
                                    src="dist/img/ComboChart.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="col-md-5">
                  <section style={{ margin: "15px 0px" }}>
                    <div className="table-cards">
                      <div className="table-cards">
                        <div className="row">
                          <div
                            className="col-md-12 p-0"
                            style={{ height: "100%" }}
                          >
                            <div
                              className="small-box"
                              style={{
                                height: "70px",
                                padding: "5px",
                                borderRadius: "2.5PX",
                                display: "flex",
                              }}
                            >
                              <div
                                className="inner"
                                // onClick={UniDetails}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <div>
                                    <h5 className="eleven_font_class"
                                      style={{
                                        color: "#1F3977"
                                      }}
                                    >
                                      Likes
                                    </h5>
                                  </div>

                                  <div
                                    className="d-flex twenty_font_class"
                                    style={{
                                      flexWrap: "wrap",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {/* <div>{n_data.likes_count}</div> */}
                                  </div>
                                </div>

                                <div className="all_icon_imgs_div"
                                  style={{
                                    background: "#BEF5C3"
                                  }}
                                >
                                  <img className="all_icon_imgs"
                                    src="dist/img/Thumbs_Up.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>








            </div>
          </div>



        </div>
      </div >
    </>
  )
}


